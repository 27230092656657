body
{
  background-color: #fafafa;
  color: #333!important;
}
.btn {
  background-color: #789367!important;
  border: none!important;
  color: #ffffff!important;
  padding: 10px 20px;
  border-radius: 5px;
}


.title-container {
  display: inline-flex; /* oder 'inline-block', je nach Bedarf */
  align-items: center; /* Damit sie vertikal zentriert sind */
}

.status-link {
  cursor: pointer;
  text-decoration: none;
}

.status-link i {
  margin-right: 5px;
}


.testred
{
  background-color:#ff0000!important;
}

.btn:hover {
  background-color: #5f754f!important; /* Etwas dunkleres Grün beim Hover */
}

.card {
  border: 1px solid #ddd!important; /* Schwacher Rand für die Karte */
  border-radius: 8px; /* Abgerundete Ecken */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Leichter Schatten für die Karte */
  transition: box-shadow 0.3s ease-in-out; /* Sanfter Übergang beim Hover */
  color: #333!important;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Intensiverer Schatten beim Hover */
  color: #333!important;
}

footer {
  background-color: #f1f1f1; /* Helles Grau für den Footer-Hintergrund */
  color: #333!important; /* Dunkles Grau für den Text */
  padding: 20px 0;
  position:relative;
  margin-top:25px;
  left:0;
  bottom:0;
  right:0;
}

footer a {
  color: #333!important; /* Gleiche Farbe für die Links wie der Text */
  text-decoration: none;
}

footer a:hover {
  color: #5f754f!important; /* Grüner Farbton beim Hover für Konsistenz mit Buttons */
  text-decoration: underline;
}

footer h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

footer p {
  margin: 0;
}

footer ul {
  list-style: none;
  padding: 0;
}

footer ul li {
  margin-bottom: 5px;
}

/* Listenansicht Styling */
.list-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.list-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-item h5 {
  margin: 0;
  font-size: 1.25rem;
}

.list-item p {
  margin: 0.5rem 0;
}

.list-item-img {
  width: 150px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}


.greenlink {
  color: #789367;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.greenlink:hover {
  text-decoration: underline;
}

.modal-input
{
  width: 100%;
}
.modal-buttons
{
  padding: 10px;
}

.page-link, .page-link:hover, .page-link:active, .page-link:after
{
  color: #789367;

}

.active>.page-link, .page-link.active
{
  border-color: #789367;
  background-color: #789367;
}


.image-gallery-icon:hover {
  color: #5f754f!important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus
{
  border: 4px solid #5f754f!important;
}

.image-gallery-thumbnail:hover
{
  border: 4px solid #5f754f!important;
}

.separator {
  border: 0;
  border-top: 1px solid #ccc; /* Farbe und Dicke der Linie */
  margin: 10px 0; /* Abstand oben und unten */
}
.display-linebreak {
  white-space: pre-line;
}

.modal-buttons
{
  text-align: center;
}

.form-group
{
  padding-top: 5px;
  padding-bottom: 5px;
}


@media (min-width: 1200px) {
  .container{
    max-width: 1000px;
  }
}

@media (max-width: 768px)
{
  .card-last-with-margin
  {
    margin-bottom: 1rem!important
  }
  .ReactModal__Content
  {
    max-width: 90%;
  }
}

.image-gallery-container {
  display: flex;
  overflow-x: scroll;
  gap: 10px;
  padding-bottom: 10px;
}

.draggable-img {
  display: inline-block;
  min-width: 150px;
  cursor: grab;
  position: relative;
}


.upload-section {
  display: flex;
  align-items: center;
}

.progress {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 10px;
}

.progress-bar {
  height: 20px;
  background-color: #28a745;
  width: 0%;
  transition: width 0.4s ease;
}


.card-img-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Das entspricht einem 16:9 Seitenverhältnis */
  overflow: hidden;
}

.card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Schneidet das Bild zu, um das Seitenverhältnis zu wahren */
}

.image-gallery-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Bild wird zugeschnitten, um den Container auszufüllen */
}

.image-gallery-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-gallery-container
{
  padding:10px;
}

.favorite-button {
  display: inline-flex; /* Flexbox für die horizontale Ausrichtung */
  align-items: center; /* Vertikale Zentrierung des Texts und Icons */
  cursor: pointer;
  font-size: 1rem; /* Standard-Schriftgröße */
  color: inherit;
  text-decoration: none;
}

.star-icon {
  color: gold; /* Setzt die Farbe des Sterns auf Gold */
  margin-right: 8px; /* Abstand zwischen dem Stern und dem Text */
}
